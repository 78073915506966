<template>
  <div class="upload-wrapper">
    <el-upload
      class="upload-demo"
      drag
      multiple
      action="/"
      :auto-upload="false"
      :on-remove="handleRemove"
      :on-change="onChange"
      :file-list="fileList"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Arraste os arquivos aqui ou <em>clique para selecionar!</em>
      </div>

      <el-collapse-transition slot="tip">
        <div class="standards" v-if="isStandardsVisibled">
          <span> Arquivos esperados: </span>
          <div class="standards-row-items">
            <span
              v-for="(item, index) in standards"
              :key="`standards-${index}`"
            >
              {{ item }}
            </span>
          </div>
          <el-button
            class="standards-close"
            icon="el-icon-close"
            @click="closeStandards"
          ></el-button>
        </div>
      </el-collapse-transition>
    </el-upload>

    <div class="files-box">
      <div
        :class="!item.details.error.status ? 'file' : 'file danger'"
        v-for="(item, index) in fileList"
        :key="`error-detail-${index}`"
      >
        <div>
          <span class="file-name">{{ item.raw.name }}</span>
          <span class="file-size">({{ item.details.size }}kb)</span>
          <div class="text-error" v-if="item.details.error.status">
            <i class="el-icon-warning"></i>
            <span>{{ item.details.error.message }}</span>
          </div>
        </div>
        <el-popover
          placement="top"
          width="400"
          v-model="item.details.isPopVisible"
        >
          <div class="confirm-items">
            <i class="el-icon-info"><span>Remover item da lista?</span></i>
            <p>Arquivo: {{ item.name }}</p>
            <p>Após a remoção não será possível restaurar a lista.</p>
            <div class="buttons-wrapper">
              <el-button
                class="custom-button secondary-button"
                @click="handleCancel(item)"
              >
                Cancelar</el-button
              >
              <el-button class="custom-button" @click="handleDelete(index)">
                Confirmar</el-button
              >
            </div>
          </div>
          <el-button
            slot="reference"
            class="file-close"
            icon="el-icon-close"
          ></el-button>
        </el-popover>
      </div>
    </div>

    <div class="footer-menu-item">
      <span
        :class="
          fileList.length === standards.length
            ? 'file-count'
            : 'file-count text-error'
        "
        @click="openStandards"
      >
        {{ fileList.length }}/{{ standards.length }} arquivos importados
      </span>
      <div>
        <el-button
          id="clear-button"
          class="secondary-button"
          :disabled="fileList.length === 0"
          @click="handleClearList"
        >
          Limpar dados
        </el-button>
        <el-button
          id="send-button"
          class="custom-button"
          :disabled="hasErrorOnList"
          @click="sendFiles"
        >
          Salvar dados
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  data() {
    return {
      count: 0,
      fileList: [],
      isStandardsVisibled: true,
      standards: [
        'uber',
        'rm',
        'piramide',
        'razao',
        'rateio',
        'encargos',
        'beneficios',
        'folha',
      ],
    }
  },
  methods: {
    handleCancel(item) {
      item.details.isPopVisible = false
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleDelete(position) {
      this.fileList.splice(position, 1)
      this.fileList.forEach((file, index, array) => {
        let existsError = false
        this.fileList.forEach((current, index2, array2) => {
          if (index2 !== index) {
            if (file.details.name.includes(current.details.name)) {
              existsError = true
            }
          }
        })
        if (!existsError) {
          this.fileList[index].details.error.status = false
        }
      })
    },
    closeStandards() {
      this.isStandardsVisibled = false
    },
    openStandards() {
      this.isStandardsVisibled = true
    },
    normalizeFileName(name) {
      return name
        .trim()
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    },
    handleClearList() {
      this.$confirm(
        'Tem certeza que deseja limpar os dados das planilhas importadas?',
        'Atenção!',
        {
          confirmButtonText: 'Limpar',
          cancelButtonText: 'Cancelar',
          type: '',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
        }
      )
        .then(() => {
          this.fileList = []
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Dados limpos.',
          })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: 'Operação cancelada.',
          })
        })
    },
    onChange(file) {
      const extensions = ['XLS', 'XLSX', 'XLSM']
      const type = file.raw.name.split('.').pop().toUpperCase()
      const fileName = this.normalizeFileName(file.name)
      let errorStatus = false

      if (!extensions.some((element) => element === type)) {
        errorStatus = true
        this.fileList.push({
          ...file,
          details: {
            name: `error-format`,
            isPopVisible: false,
            size: (file.size / 1024).toFixed(),
            error: {
              status: true,
              message: 'Arquivo não está no formato esperado.',
            },
          },
        })
      } else {
        const hasOnFileList = this.fileList.some((element) => {
          const elementName = this.normalizeFileName(
            element?.details?.name || element.name
          )
          return fileName.includes(elementName)
        })
        if (hasOnFileList) {
          let position = 0

          const hasOnStandards = this.standards.some((element) => {
            const elementName = this.normalizeFileName(element)

            if (!fileName.includes(elementName)) {
              position++
            } else {
              return {
                status: true,
                position,
              }
            }
          })

          if (hasOnStandards) {
            errorStatus = true
            this.fileList.push({
              ...file,
              details: {
                name: this.standards[position],
                isPopVisible: false,
                size: (file.size / 1024).toFixed(),
                error: {
                  status: true,
                  message: 'Arquivo já existe na lista de importação.',
                },
              },
            })
          } else {
            errorStatus = true
            this.fileList.push({
              ...file,
              details: {
                name: 'error-standards',
                isPopVisible: false,
                size: (file.size / 1024).toFixed(),
                error: {
                  status: true,
                  message: 'Arquivo não está no padrão de nome esperado.',
                },
              },
            })
          }
        } else {
          errorStatus = false
        }

        if (!errorStatus) {
          const fileName = this.normalizeFileName(file.name)
          let position = 0

          const hasOnStandards = this.standards.some((element) => {
            const elementName = this.normalizeFileName(element)

            if (!fileName.includes(elementName)) {
              position++
            } else {
              return {
                status: true,
                position,
              }
            }
          })

          if (hasOnStandards) {
            errorStatus = false
            this.fileList.push({
              ...file,
              details: {
                name: this.standards[position],
                isPopVisible: false,
                size: (file.size / 1024).toFixed(),
                error: {
                  status: false,
                  message: '',
                },
              },
            })
          } else {
            errorStatus = true
            this.fileList.push({
              ...file,
              details: {
                name: 'error-standards',
                isPopVisible: false,
                size: (file.size / 1024).toFixed(),
                error: {
                  status: true,
                  message: 'Arquivo não está no padrão de nome esperado.',
                },
              },
            })
          }
        }
      }
    },
    async sendFiles() {
      const newList = this.fileList.map((element) => {
        return {
          name: element.details.name,
          file: element.raw,
        }
      })

      this.$emit('upload', {
        list: newList,
      })
    },
  },
  computed: {
    hasErrorOnList() {
      if (
        this.fileList.length !== this.standards.length ||
        this.fileList.some((e) => e.details.error.status)
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
