<template>
  <div class="import-wrapper">
    <div class="title-container">
      <h3>Planilhas</h3>
      <el-date-picker
        v-model="date"
        type="month"
        placeholder="Selecione um mês"
        format="MM/yyyy"
        :clearable="false"
        :picker-options="datePickerOptions"
        @change="newDatePeriod"
      >
      </el-date-picker>
    </div>
    <el-skeleton v-if="loading" class="loading-data" animated :rows="10" />
    <div v-else class="header-container">
      <div class="status-container">
        <span class="status-container-title">Status</span>
        <div v-if="hasImported">
          <span> Planilhas importadas </span>
        </div>
        <div v-else>
          <span> Aguardando importação das planilhas </span>
        </div>
      </div>
      <div class="action-container" v-if="hasImported">
        <span class="status-container-title">Ações</span>
        <div class="button-icon" @click="deleteSheet">
          <span> Excluir Planilhas </span>
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Import',
  data() {
    return {
      date: '',
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
    }
  },
  props: {
    hasImported: Boolean,
    loading: Boolean,
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),
    ...mapActions(['updateSelectedMonth']),

    deleteSheet() {
      this.$confirm(
        'Tem certeza que deseja excluir essas planilhas?',
        'Atenção!',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
          type: '',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
        }
      )
        .then(() => {
          this.$emit('delete')
        })
        .catch(() => {})
    },
    newDatePeriod() {
      const newDate = this.date
      this.updateSelectedMonth(newDate)
      this.$emit('dateChange', { date: newDate })
    },
  },
  created() {
    this.date = this.getSelectedMonth()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
