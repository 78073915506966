<template>
  <div class="home-page">
    <div class="error-page" v-if="hasError">
      <h4>Ocorreu um erro inesperado!</h4>
    </div>
    <Import
      v-else
      :hasImported="hasImported"
      @delete="deleteGeneralSheetsMain"
      @dateChange="dateChange"
      :loading="loading"
    />
    <Upload v-if="getImportedStatus" @upload="handleUpload" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { deleteGeneralSheet, sendGlobalSheets } from '@/service/integration'
import { getMonthStatus } from '@/service/generalSheetsStatus'
import Upload from './Partials/Upload/Upload.vue'
import Import from './Partials/Import/Import.vue'

export default {
  name: 'Home',
  data() {
    return {
      isUploadOpen: false,
      hasImported: true,
      hasError: false,
      date: '',
      loading: true,
    }
  },
  components: {
    Upload,
    Import,
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),

    async handleUpload({ list }) {
      const loading = this.$loading({
        lock: true,
        text: 'Em alguns instantes, as planilhas estarão importadas.',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const date = this.date
        const { data } = await sendGlobalSheets(list, date)
        this.hasImported = true
        if (data.hasInconsistency) {
          this.$confirm(
            `Os seguintes centros de custo apresentaram inconsistências entre o Razão e o Pirâmide: ${(
              data.inconsistencyList || []
            ).join(', ')}. \nVocê deseja manter ou excluir as planilhas?`,
            'Inconsistências foram encontradas nas planilhas importadas!',
            {
              showClose: false,
              type: '',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              distinguishCancelAndClose: true,
              confirmButtonText: 'Manter',
              cancelButtonText: 'Excluir',
              confirmButtonClass: 'primary-button',
              cancelButtonClass: 'secondary-button',
            }
          )
            .then(() => {
              this.$router.push({ name: 'Projects' })
            })
            .catch((action) => {
              if (action === 'cancel') {
                this.deleteGeneralSheetsMain()
              }
            })
        } else {
          this.$router.push({ name: 'Projects' })
        }
      } catch (error) {
        const message = error.response.data
        this.handleUploadErrror(message)
      } finally {
        loading.close()
      }
    },
    handleUploadErrror(message) {
      const maxCountCharacters = 30
      if (message.length > maxCountCharacters) {
        const title = message.split(':')[0]
        this.$alert(`<p class="text-wrap">${message}</p>`, title, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Fechar',
        })
      } else {
        this.$notify.error({
          position: 'bottom-left',
          dangerouslyUseHTMLString: true,
          message: `${message}`,
        })
      }
    },
    async deleteGeneralSheetsMain() {
      const loading = this.$loading({
        lock: true,
        text: 'Em alguns instantes, as planilhas estarão excluídas.',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const date = this.date
        await deleteGeneralSheet(date)
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Planilhas excluídas com sucesso!',
          duration: 5000,
        })
        this.hasImported = false
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
        this.hasImported = true
      } finally {
        loading.close()
      }
    },
    async dateChange({ date }) {
      this.date = date
      await this.handleGetStatus()
    },
    async handleGetStatus() {
      try {
        this.loading = true
        const { data } = await getMonthStatus(this.date)
        if (data.hasSheets === true) {
          this.hasImported = true
        } else {
          this.hasImported = false
        }
      } catch (error) {
        this.hasError = true
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        this.loading = false
      }
    },
  },
  async created() {
    if (localStorage.getItem('accessLevel') !== 'pmo')
      this.$router.push('/projects')

    this.date = this.getSelectedMonth()

    this.handleGetStatus()
  },
  computed: {
    getImportedStatus() {
      return !this.hasImported && !this.loading
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
