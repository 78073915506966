import api from '../instance'

const dictionary = {
  folha: 'sheet',
  encargos: 'charges',
  beneficios: 'benefits',
  rateio: 'apportionment',
  piramide: 'pyramid',
  razao: 'ledgerBook',
  rm: 'registrationData',
  uber: 'transport',
}

export function deleteGeneralSheet(date) {
  return api.delete('/integration', {
    data: { date },
  })
}

export function deleteManagerSheet(id) {
  return api.delete('/managerIntegration', {
    data: {
      financialReportId: id,
    },
  })
}

export function sendGlobalSheets(files, date) {
  const formData = new FormData()

  files.forEach(({ name, file }) => {
    formData.append(dictionary[name], file)
  })

  formData.append('date', date)

  return api.post('/integration', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function sendManagerSheet(
  file,
  date,
  id,
  pastBaseline = null,
  pastOutlook = null
) {
  const formData = new FormData()

  formData.append('manager', file)
  formData.append('financialId', id)
  formData.append('pastBaseline', pastBaseline)
  formData.append('pastOutlook', pastOutlook)

  return api.post('/managerIntegration', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
